<template>
  <v-card>
    <div class="pa-5 mb-5">
      <v-card-title
        primary-title
        class="title"
      >
        Routage de la Revue des Parents
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="selectedDocument"
            :items="documents"
            :item-text="item => `${item.label}`"
            label="Document *"
            return-object
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-btn
            class="primary"
            :disabled="!selectedDocument"
            @click="$emit('export', { url: `/api/admin/routage/${selectedDocument.key}`})"
          >
            Routage
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ExportRoutageComponent',
  data() {
    return {
      documents: [
        { label: 'Adresses Postales (.txt)', key: 'adresses_postales' },
        { label: 'Adresses Mails (.csv)', key: 'adresses_mails' },
        { label: 'Rapport de routage (.doc)', key: 'rapport' },
        { label: 'Rapport de routage par département (.csv)', key: 'rapport_departement' },
      ],
      selectedDocument: null,
    };
  },
};
</script>