<template>
  <div>
    <ExportRoutageComponent
      @export="({ url }) => { 
        downloadingFile = {
          url: url,
          method: 'GET' 
        }
      }"
    />
    <Downloader
      :downloading-file="downloadingFile"
      label="export Routage"
      @downloadSuccess="downloadingFile = null"
    />
  </div>
</template>

<script>
import ExportRoutageComponent from '../../components/importsExports/ExportRoutageComponent';
import Downloader from '../../components/partials/Downloader';

export default {
  components: {
    ExportRoutageComponent,
    Downloader,
  },
  data() {
    return {
      downloadingFile: null,
    };
  },
};
</script>